<template>
  <div class="stat-list">
    <div class="stat-list__item">
      <div class="stat-list__info">
        <div class="stat-list__count">10</div>
        <p class="stat-list__desc">Кол-во посещенией всего</p>
      </div>
    </div>
    <div class="stat-list__item stat-list__item--success">
      <div class="stat-list__info">
        <div class="stat-list__count">10</div>
        <p class="stat-list__desc">Кол-во посещений с начала 2021 г.</p>
      </div>
    </div>
    <div class="stat-list__item stat-list__item--neutral">
      <div class="stat-list__info">
        <div class="stat-list__count">10</div>
        <p class="stat-list__desc">Кол-во посещений за вчера</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stat',
  data() {
    return {
      stat: {
        abiturients: {},
        register: {},
        scan_statement: {},
        app_statement: {},
        educations: {},
      },
    }
  },
  created() {
    // this.$store.dispatch('GET_STAT')
    //   .then(response => {
    //     this.stat = response.data
    //   })
  }
}
</script>

<style lang="sass">
.stat-list
  display: grid
  grid-template-columns: repeat(auto-fill, 292px)
  column-gap: 15px
  row-gap: 20px
  margin-bottom: 20px

.stat-list__item
  min-height: 100px
  padding: 15px
  border-radius: 7px
  background-image: linear-gradient(256.92deg, #7ec7ff 0.85%, #1d87dd 98.53%)
  box-shadow: 0 2px 6px rgba(43, 147, 231, 0.25)

.stat-list__item--abort
  background-image: linear-gradient(256.92deg, #ffbdc8 0.85%, #ea5467 98.53%)

.stat-list__item--success
  background-image: linear-gradient(256.21deg, #bde9c8 0%, #38bf4e 96.91%)

.stat-list__item--neutral
  background-image: linear-gradient(256.21deg, #fadcb6 0%, #ff9416 96.91%)

.stat-list__item--archive
  background-image: linear-gradient(256.21deg, #ddd 0%, #aaa 96.91%)

.stat-list__count
  color: #fff
  font-size: 24px
  font-weight: 700

.stat-list__desc
  margin-bottom: 0
  color: #fff
  font-size: 14px
</style>
