<template>
  <div class="content">
    <h1 class="title title--indent">Главная</h1>
    <app-stat />
    <app-calendar />
  </div>
</template>

<script>
import AppCalendar from "@/components/AppCalendar"
import AppStat from "@/components/AppStat"

export default {
  name: 'Calendar',
  components: {
    AppCalendar,
    AppStat
  },
}
</script>
