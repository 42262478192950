<template>
  <div class="content__body">
    <FullCalendar
      :options="calendarOptions"
      ref="fullCalendar"
    />
  </div>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import { format } from 'date-fns'

  export default {
    name: 'AppCalendar',
    components: {
      FullCalendar
    },
    data() {
      return {
        calendarOptions: {
          plugins: [ dayGridPlugin, interactionPlugin ],
          initialView: 'dayGridMonth',
          locale: 'ru',
          buttonText: {
            today: 'Сегодня',
          },
          events: [],
          customButtons: {
            next: {
              click: this.nextButton,
            },
            prev: {
              click: this.prevButton,
            },
          },
          headerToolbar: {
            left: 'title',
            center: '',
            right: 'today prev,next'
          }
        },
        start: '',
        end: '',
      }
    },
    mounted() {
      this.handleCalendar();
    },
    methods: {
      getData(start, end) {
        this.$store.dispatch('calendar/GET_DATA', { start, end })
          .then((response) => {
            this.calendarOptions.events = response.data;
          })
      },
      handleCalendar(){
        let calendarApi = this.$refs.fullCalendar.getApi()
        // console.log(calendarApi.view.activeStart);
        // console.log(calendarApi.view.activeEnd);
        this.initDateProp(format(calendarApi.getDate(), 'yyyy-MM-dd'), format(calendarApi.view.activeEnd, 'yyyy-MM-dd'))
        this.getData(this.start, this.end);

      },
      initDateProp(start, end) {
        this.start = start
        this.end = end
      },
      nextButton() {
        const calendarApi = this.$refs.fullCalendar.getApi()
        this.initDateProp(format(calendarApi.getDate(), 'yyyy-MM-dd'), format(calendarApi.view.activeEnd, 'yyyy-MM-dd'))
        calendarApi.next()
        this.getData(this.start, this.end)
      },

      prevButton() {
        const calendarApi = this.$refs.fullCalendar.getApi()
        this.initDateProp(format(calendarApi.getDate(), 'yyyy-MM-dd'), format(calendarApi.view.activeEnd, 'yyyy-MM-dd'))
        calendarApi.prev()
        this.getData(this.start, this.end)
      },
    }
  }
</script>

<style lang="sass">
  @import "@/assets/common/index.scss"

</style>
